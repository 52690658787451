var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"BonusConfigDetail","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-auto ml-auto"},[_c('key-value-card',{attrs:{"title":"Joker Bonus Configuration","loading":_vm.jokerBonusConfigData.isLoading,"rows":_vm.jokerBonusConfig,"color":_vm.color,"header":_vm.dataHeaders}},[_c('div',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","elevation":"1","fab":"","small":""},on:{"click":_vm.exportConfig}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-down-bold")])],1)]}}])},[_c('span',[_vm._v("Export")])]),(
							_vm._usrFlagsSome({
								key: 'bonus.group_configs.detail',
								val: _vm.permissions.EDIT,
							})
						)?_c('EditJokerBonusConfig',{attrs:{"editJokerBonusConfigData":_vm.jokerBonusConfigCopy,"color":_vm.JB_configColor,"jokerBonusContribType":_vm.contribType,"allowEditOfPreMadeConfig":true},on:{"submit":_vm.onJBConfigEditSubmit},scopedSlots:_vm._u([{key:"button",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","elevation":"1","fab":"","small":""},on:{"click":_vm.openJBEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,2912317002)}):_vm._e(),(_vm.jackpotConfigDetail.deleted == null)?_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog, attrs }){return [(
									_vm._usrFlagsSome({
										key: 'bonus.group_configs.detail',
										val: _vm.permissions.DELETE,
									}) && _vm.jackpotConfigDetail.deleted == null
								)?_c('v-tooltip',{attrs:{"color":"error darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('span',_vm._g({},tooltip),[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"error","link":""}},'v-btn',attrs,false),dialog),[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e()]}}],null,false,4115513198),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-toolbar',{staticClass:"red d-flex white--text font-weight-bold"},[_vm._v(" Delete ")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete this Bonus Configuration? ")]),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.handleDeactivateButton}},[_vm._v(" YES ")])],1)],1)],1):_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog, attrs }){return [(
									_vm._usrFlagsSome({
										key: 'bonus.group_configs.detail',
										val: _vm.permissions.DELETE,
									}) && _vm.jackpotConfigDetail.deleted !== null
								)?_c('v-tooltip',{attrs:{"color":"error darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('span',_vm._g({},tooltip),[_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"error","link":""}},'v-btn',attrs,false),dialog),[_c('v-icon',[_vm._v("mdi-restore")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Restore")])]):_vm._e()]}}]),model:{value:(_vm.dialogReactivate),callback:function ($$v) {_vm.dialogReactivate=$$v},expression:"dialogReactivate"}},[_c('v-card',[_c('v-toolbar',{staticClass:"red d-flex white--text font-weight-bold"},[_vm._v(" Restore ")]),_c('v-card-text',[_vm._v(" Are you sure you want to restore this Bonus Configuration? ")]),_c('v-card-actions',{staticClass:"justify-space-between"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeReactivate}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.handleReactivateButton}},[_vm._v(" YES ")])],1)],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }